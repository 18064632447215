/* ================================= Style ================================ */

/* Body */
body {
  overflow-y: auto;
}

/* Display */
.d-contents {
  display: contents;
}

/* Icons */
.fa-1x {
  font-size: 1.3rem;
}
.whatsapp-color {
  color: #fff;
  background: linear-gradient(#25d366, #25d366) 14% 84%/16% 16% no-repeat,
    radial-gradient(#25d366 58%, transparent 0);
}
.telegram-color {
  color: #0088cc;
}
.telegram-color.fa-1x {
  font-size: 1rem;
}
.icon-panel-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Spinner */
.spinner-border-xs {
  border-width: 0.16em;
  height: 0.7rem;
  width: 0.7rem;
}

.form-control:focus,
.form-select:focus,
.form-control.is-invalid:focus {
  outline: none;
  box-shadow: none;
  /* border-color: #ccc; */
  border-color: #7f7f7f;
}

/* Dropdown Menu & Toogle */
.dropdown-toggle.top-profile:after {
  display: none;
}
.dropdown .dropdown-menu.top-profile.show {
  margin-top: 10px;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #e0e0e0;
  color: #42484e;
  text-decoration: none;
}
@media (max-width: 767px) {
  .dropdown .dropdown-menu.mr.show {
    margin-right: 20px;
  }
}
.dropdown-toggle:after {
  border: none;
}

/* Border */
.border-secondary {
  border-color: #737d85 !important;
}
.border-soft-grey {
  border: 1px solid #d1d1d1 !important;
}
.border-orange {
  border-color: #fca311 !important;
}

/* Margin */
.ms-05 {
  margin-left: 0.12rem !important;
}

/* OTP */
#otp-verification > [data-testid="otp-input-root"] {
  display: block !important;
  margin-bottom: 20px;
}
#otp-verification > [data-testid="otp-input-root"] > input {
  border: 1.5px solid #1a84b0;
  border-color: #1a84b0 !important;
  border-radius: 8px;
  box-shadow: none;
  width: 54px !important;
  height: 54px !important;
  font-size: 16px !important;
  color: #000 !important;
}
#otp-verification > [data-testid="otp-resend-root"] {
  display: block !important;
}
#otp-verification > [data-testid="otp-resend-root"] span {
  display: block;
  margin-bottom: 10px;
}
#otp-verification > [data-testid="otp-resend-root"] button {
  background-color: none;
  border: none;
  border-radius: 0.25rem;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
}

/* Datatables */
.table-responsive .table {
  width: 100% !important;
}
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:after {
  opacity: 0.6 !important;
}
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_desc_disabled:before,
table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_desc_disabled:before {
  bottom: 50% !important;
  content: "▲" !important;
}
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_desc_disabled:after,
table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_desc_disabled:after {
  content: "▼";
  top: 50%;
}

table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:before,
table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:before {
  display: block;
  font-size: 0.8em !important;
  line-height: 9px !important;
  opacity: 0.125;
  position: absolute;
  right: 10px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  color: #333 !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(230, 230, 230, 0.1);
  background: linear-gradient(
    to bottom,
    rgba(230, 230, 230, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #fff !important;
  background: #1a84b0;
  border: 0.3px solid #fff !important;
}

/* Background */
.bg-y-green {
  background-color: #dcfdfd;
}
.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.9);
}
.bg-light-grey {
  background-color: #f6f6f7;
}
.bg-left-chat-message {
  background-color: #fff;
}
.bg-right-chat-message {
  background-color: #d9e5fc;
}
.bg-full-login {
  background: url(../bg-login.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}

/* Sidebar */
.sidebar-item.active,
.sidebar-item-2.active {
  background-color: #fca311;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 90%;
}
body[data-sidebar-behavior="compact"] .sidebar-item-2 {
  padding: 0;
}
.sidebar-brand {
  padding: 1.08rem 1.3rem;
  border-bottom: 1.5px solid #fff;
}
.dropdown-item {
  padding: 0.35rem 1rem;
}
.nav-flag.active,
.nav-flag:hover,
.nav-icon.active,
.nav-icon:hover {
  color: #fca311;
}
.card-link {
  color: #495057;
}
.card-link:hover {
  color: #fca311;
}
.sidebar-link,
a.sidebar-link,
.sidebar-link i,
.sidebar-link svg,
a.sidebar-link i,
a.sidebar-link svg {
  color: #e9e9e9;
}
.sidebar-link:hover,
a.sidebar-link:hover,
.sidebar-link:hover i,
.sidebar-link:hover svg,
a.sidebar-link:hover i,
a.sidebar-link:hover svg {
  color: #fca311 !important;
}
a.sidebar-link img {
  filter: hue-rotate(100deg);
  filter: brightness(0) invert(2);
  margin-right: 12px;
}
a.sidebar-link:hover img,
a.sidebar-link:focus img {
  filter: none;
}
a.sidebar-link.active img {
  filter: grayscale(100%) brightness(40%) !important;
}
.sidebar-item {
  margin-bottom: 8px;
}
.sidebar-item > .sidebar-link.active,
.sidebar-item > .sidebar-link.active svg,
.sidebar-item-2 > .sidebar-link.active,
.sidebar-item-2 > .sidebar-link.active svg {
  color: #293042 !important;
  /* color: #fff !important; */
  font-weight: 480;
}

/* Form */
.form-control-mui {
  color: #495057;
  display: block;
  font-size: 0.825rem;
  font-weight: 400;
  line-height: 2;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
.form-select-lg {
  font-size: 0.83rem;
}

/* Select2 */
.select2-container .select2-selection--single {
  border: 1px solid #ced4da !important;
}
.select2-container .select2-selection--single,
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  height: 32px !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 28px !important;
}

/* Text */
.text-blck {
  color: #020202 !important;
}

/* Toogle Custom Switch */
.custom-switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 39px;
  cursor: pointer;
}
.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 30px;
  transition: background-color 0.4s;
}
.slider::before {
  content: "";
  position: absolute;
  width: 35px;
  height: 35px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.custom-switch.on .slider {
  background-color: #fca311; /* Warna saat aktif */
}
.custom-switch.on .slider::before {
  transform: translateX(51px); /* Gerakkan lingkaran ke kanan */
}
.slider-icon {
  position: absolute;
  top: 50%;
  left: 21%;
  transform: translate(-50%, -50%);
  color: #fca311;
  font-size: 14px;
  pointer-events: none;
}
.custom-switch.on .slider-icon {
  left: 79%; /* Gerakkan lingkaran ke kanan */
  color: #fca311;
}

/* Toogle Custom Switch Form */
.custom-switch-form {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 30px;
  cursor: pointer;
}
.slider-form {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 30px;
  transition: background-color 0.4s;
}
.slider-form::before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.custom-switch-form.on .slider-form {
  background-color: #fca311; /* Warna saat aktif */
}
.custom-switch-form.on .slider-form::before {
  transform: translateX(35px); /* Gerakkan lingkaran ke kanan */
}
.slider-icon-form {
  position: absolute;
  top: 48%;
  left: 21%;
  transform: translate(-50%, -50%);
  color: #fca311;
  font-size: 14px;
  pointer-events: none;
}
.custom-switch-form.on .slider-icon-form {
  left: 75%; /* Gerakkan lingkaran ke kanan */
  color: #fca311;
}

/* Circle Number */
.circle-number-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.circle-number {
  width: 45px;
  height: 45px;
  background-color: #fca311;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.circle-text {
  display: flex;
  flex-direction: column;
}
.main-text {
  font-size: 16px;
  color: #fca311;
  font-weight: 500;
}
.sub-text {
  font-size: 11px;
}

/* Chat */
.chat-messages {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 310px);
  /* overflow-y: auto; */
}
.contact-dt-height {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 295px);
  /* overflow-y: auto; */
}
.chat-messages-2 {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 305px);
  /* overflow-y: auto; */
}
.chat-user {
  /* max-height: 665px; */
  padding-left: 0 !important;
  margin-bottom: 0px;
}
.nav-assistant {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 305px);
  /* overflow-y: auto; */
}
.navchat-right {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 290px);
  /* overflow-y: auto; */
}
.navcontact-right {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 385px);
}
.scroll-to-bottom-btn {
  padding: 0;
  bottom: 12px;
  right: 21px;
  width: 40px;
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* React Component */
.react-tel-input .form-control {
  width: 100% !important;
}
.row > .space-grid {
  flex-shrink: 0;
  margin-top: var(--bs-gutter-y);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  width: 50%;
}

/* Link */
a,
a:hover {
  color: #1a84b0;
  text-decoration: none;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #e0e0e0;
  color: #3c3c3c;
}

input:focus,
input.form-control:focus,
select.form-select:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

/* Image */
.img-logo-login {
  height: 35px;
  width: 163px;
  position: relative;
  z-index: 1;
}

/* Custom Border */
.custom-border-col {
  border-right: none;
}
@media (min-width: 768px) {
  .custom-border-col {
    border-right: 1px solid #d1d1d1; /* Bootstrap default border color */
  }
}
@media (max-width: 768px) {
  .custom-border-col {
    margin-bottom: 20px; /* Bootstrap default border color */
  }
  .custom-border-button {
    margin-top: 20px;
  }
}

/* Card Panel */
.card-title {
  font-size: 1.2rem;
}
.header-chart {
  padding: 1rem 1.25rem 0 1rem;
}
.card-body.img-panel {
  background-image: url("../panel-transparent.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 16vh 90%;
}
.card.bg-y-green:hover {
  transform: scale(1.07);
  transition: transform 0.8s;
  cursor: default;
}

.card.fill-subscription:hover {
  background-color: #f2f2f2;
}
.card.fill-subscription.active {
  background-color: #fee7cf;
  border-color: #fca311 !important;
}

/* Background */
.bg-orange {
  background-color: #fca311 !important;
}
.bg-orange-soft {
  background-color: #fee7cf !important;
}
.bg-primary-2 {
  background-color: #293042 !important;
}
.bg-pink-py {
  --bs-bg-opacity: 1;
  background-color: #ffb1c0 !important;
}
.bg-purple-py {
  --bs-bg-opacity: 1;
  background-color: #f8ccff !important;
}
.bg-green-py {
  --bs-bg-opacity: 1;
  background-color: #abf5df !important;
}
.bg-orange-py {
  background: #fee7cf !important;
}

/* Rounded */
.custom-start-rounded {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}
.rounded-4 {
  border-radius: 1rem !important;
}
.rounded-5 {
  border-radius: 1.2rem !important;
}

/* DatePicker */
input.datepicker {
  padding: 0.25rem 0.7rem;
}
.form-control.datepicker:disabled,
.form-control.datepicker[readonly] {
  background: none;
  opacity: 1;
}
.datetimepicker {
  padding: 10px !important;
}
#datepicker-inline > .datepicker.datepicker-inline td,
#datepicker-inline > .datepicker.datepicker-inline th {
  width: 80px !important;
  height: 35px !important;
}
#datepicker-inline > .datepicker-inline {
  width: auto !important;
}
.datepicker-dropdown {
  padding: 10px !important;
}
.datepicker > .datepicker-days td,
.datepicker > .datepicker-days th {
  width: 30px !important;
  height: 30px !important;
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  background-color: #7fc1fc !important;
  color: #ffffff !important;
  background-image: none !important;
  text-shadow: none !important;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #4bd396 !important;
  color: #ffffff !important;
  background-image: none !important;
}
.datepicker-inline {
  border: 2px solid rgba(49, 58, 70, 0.1);
}

.datetimepicker-dropdown {
  padding: 10px !important;
}
.datetimepicker td,
.datetimepicker th {
  width: 30px !important;
  height: 30px !important;
}
.datetimepicker table tr td span.active:hover,
.datetimepicker table tr td span.active:hover:hover,
.datetimepicker table tr td span.active.disabled:hover,
.datetimepicker table tr td span.active.disabled:hover:hover,
.datetimepicker table tr td span.active:active,
.datetimepicker table tr td span.active:hover:active,
.datetimepicker table tr td span.active.disabled:active,
.datetimepicker table tr td span.active.disabled:hover:active,
.datetimepicker table tr td span.active.active,
.datetimepicker table tr td span.active:hover.active,
.datetimepicker table tr td span.active.disabled.active,
.datetimepicker table tr td span.active.disabled:hover.active,
.datetimepicker table tr td span.active.disabled,
.datetimepicker table tr td span.active:hover.disabled,
.datetimepicker table tr td span.active.disabled.disabled,
.datetimepicker table tr td span.active.disabled:hover.disabled,
.datetimepicker table tr td span.active[disabled],
.datetimepicker table tr td span.active:hover[disabled],
.datetimepicker table tr td span.active.disabled[disabled],
.datetimepicker table tr td span.active.disabled:hover[disabled],
.datetimepicker table tr td span.selected,
.datetimepicker table tr td span.selected:hover,
.datetimepicker table tr td span.selected.disabled,
.datetimepicker table tr td span.selected.disabled:hover {
  background-color: #7fc1fc !important;
  color: #ffffff !important;
  background-image: none !important;
  text-shadow: none !important;
}
.datetimepicker table tr td.today,
.datetimepicker table tr td.today:hover,
.datetimepicker table tr td.today.disabled,
.datetimepicker table tr td.today.disabled:hover {
  background-color: #4bd396 !important;
  color: #ffffff !important;
  background-image: none !important;
}
.datetimepicker-inline {
  border: 2px solid rgba(49, 58, 70, 0.1);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #7fc1fc;
}
.daterangepicker .input-mini.active {
  border: 1px solid rgba(49, 58, 70, 0.3);
}
.daterangepicker .ranges li {
  border-radius: 2px;
  color: #313a46;
  font-weight: 600;
  font-size: 12px;
  font-family: "Hind Madurai", sans-serif;
}
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  border: 1px solid rgba(49, 58, 70, 0.3);
  padding: 2px;
  width: 60px;
}
.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  background-color: #7fc1fc !important;
  border: 1px solid #7fc1fc !important;
  color: #ffffff !important;
}

/* Font */
.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-28 {
  font-size: 28px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-32 {
  font-size: 32px !important;
}
.fs-34 {
  font-size: 34px !important;
}

/* text */
.text-orange {
  color: #fca311 !important;
}
.text-primary {
  color: #1a84b0 !important;
}
.text-primary-2 {
  color: #293042 !important;
}
.limit-text {
  display: -webkit-box;
  -webkit-line-clamp: 6; /* Batas jumlah garis yang akan ditampilkan */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Form Wizard */
.wizard-primary.sw-theme-default > .nav .nav-link.active {
  color: #1a84b0 !important;
}
.wizard-primary.sw-theme-default > .nav .nav-link.active:after {
  background: #1a84b0 !important;
}
.wizard-primary.sw-theme-default > .nav .nav-link.done {
  color: #faba79 !important;
}
.wizard-primary.sw-theme-default > .nav .nav-link.done:after {
  background: #faba79 !important;
}
.wizard-primary.sw-theme-arrows > .nav .nav-link.active {
  background: #1a84b0 !important;
  border-color: #1a84b0 !important;
}
.wizard-primary.sw-theme-arrows > .nav .nav-link.active:after {
  border-left-color: #1a84b0 !important;
}
.wizard-primary.sw-theme-arrows > .nav .nav-link.done {
  background: #faba79 !important;
  border-color: #faba79 !important;
}
.wizard-primary.sw-theme-arrows > .nav .nav-link.done:after {
  border-left-color: #faba79 !important;
}

/* Button */
a,
button {
  box-shadow: none !important;
}
.btn-rounded {
  border-radius: 4px;
}
.btn-primary {
  background-color: #1a84b0 !important;
  border-color: #1a84b0 !important;
  color: #fff;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #10607f !important;
  border-color: #10607f !important;
  color: #fff;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 147, 237, 0.5);
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background-color: #1a84b0;
  border-color: #1a84b0;
  color: #fff;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 147, 237, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #1a84b0;
  border-color: #1a84b0;
  color: #fff;
}

.btn-secondary {
  background-color: #0055ff;
  border-color: #0557ff;
  color: #fff;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover {
  background-color: #0045cf;
  border-color: #0045cf;
  color: #fff;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 147, 237, 0.5);
}
.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #0557ff;
  border-color: #0557ff;
  color: #fff;
}
.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 147, 237, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #0557ff;
  border-color: #0557ff;
  color: #fff;
}

.btn-secondary-fill {
  background-color: #fff;
  color: #1a84b0;
  border-color: #1a84b0;
}
.btn-check:focus + .btn-secondary-fill,
.btn-secondary-fill:focus,
.btn-secondary-fill:hover {
  background-color: #1a84b0 !important;
  border-color: #1a84b0 !important;
  color: #fff;
}
.btn-check:focus + .btn-secondary-fill,
.btn-secondary-fill:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 147, 237, 0.5);
}
.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary-fill.active,
.btn-secondary-fill:active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #1a84b0;
  border-color: #1a84b0;
  color: #fff;
}
.btn-check:active + .btn-secondary-fill:focus,
.btn-check:checked + .btn-secondary-fill:focus,
.btn-secondary-fill.active:focus,
.btn-secondary-fill:active:focus,
.show > .btn-secondary-fill.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 147, 237, 0.5);
}
.btn-secondary-fill.disabled,
.btn-secondary-fill:disabled {
  background-color: #1a84b0;
  border-color: #1a84b0;
  color: #fff;
}

.btn-tertiary {
  background-color: #fff;
  color: #1a84b0;
  border-color: #1a84b0;
}
.btn-check:focus + .btn-tertiary,
.btn-tertiary:focus,
.btn-tertiary:hover {
  background-color: #f15b5b;
  border-color: #f15b5b;
  color: #fff;
}
.btn-check:focus + .btn-tertiary,
.btn-tertiary:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 147, 237, 0.5);
}
.btn-check:active + .btn-tertiary,
.btn-check:checked + .btn-tertiary,
.btn-tertiary.active,
.btn-tertiary:active,
.show > .btn-tertiary.dropdown-toggle {
  background-color: #1a84b0;
  border-color: #1a84b0;
  color: #fff;
}
.btn-check:active + .btn-tertiary:focus,
.btn-check:checked + .btn-tertiary:focus,
.btn-tertiary.active:focus,
.btn-tertiary:active:focus,
.show > .btn-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 147, 237, 0.5);
}
.btn-tertiary.disabled,
.btn-tertiary:disabled {
  background-color: #1a84b0;
  border-color: #1a84b0;
  color: #fff;
}

.btn-fortiary {
  background-color: #44c13c !important;
  border-color: #44c13c !important;
  color: #fff;
}
.btn-check:focus + .btn-fortiary,
.btn-fortiary:focus,
.btn-fortiary:hover {
  background-color: #59bf52;
  border-color: #59bf52;
  color: #fff;
}
.btn-check:focus + .btn-fortiary,
.btn-fortiary:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 147, 237, 0.5);
}
.btn-check:active + .btn-fortiary,
.btn-check:checked + .btn-fortiary,
.btn-fortiary.active,
.btn-fortiary:active,
.show > .btn-fortiary.dropdown-toggle {
  background-color: #44c13c;
  border-color: #44c13c;
  color: #fff;
}
.btn-check:active + .btn-fortiary:focus,
.btn-check:checked + .btn-fortiary:focus,
.btn-fortiary.active:focus,
.btn-fortiary:active:focus,
.show > .btn-fortiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 147, 237, 0.5);
}
.btn-fortiary.disabled,
.btn-fortiary:disabled {
  background-color: #44c13c;
  border-color: #44c13c;
  color: #fff;
}

.sw .toolbar > .sw-btn {
  color: #fff !important;
  background-color: #1a84b0 !important;
  border: 1px solid #1a84b0 !important;
}
.sw .toolbar > .sw-btn.btn-success {
  color: #fff !important;
  background-color: #4bbf73 !important;
  border: 1px solid #4bbf73 !important;
}

.btn-outline-primary {
  border-color: #1a84b0;
  color: #1a84b0;
}
.btn-outline-primary:hover {
  background-color: #1a84b0;
  border-color: #1a84b0;
  color: #fff;
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 128, 234, 0.5);
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  background-color: #1a84b0;
  border-color: #1a84b0;
  color: #fff;
}
.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 128, 234, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  background-color: transparent;
  color: #1a84b0;
}

.btn-orange {
  background-color: #fca311;
  border-color: #fca311;
  color: #fff;
}
.btn-check:focus + .btn-orange,
.btn-orange:focus,
.btn-orange:hover {
  background-color: #ce840c;
  border-color: #ce840c;
  color: #fff;
}
.btn-check:focus + .btn-orange,
.btn-orange:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 140, 64, 0.5);
}
.btn-check:active + .btn-orange,
.btn-check:checked + .btn-orange,
.btn-orange.active,
.btn-orange:active,
.show > .btn-orange.dropdown-toggle {
  background-color: #fca311;
  border-color: #fca311;
  color: #fff;
}
.btn-check:active + .btn-orange:focus,
.btn-check:checked + .btn-orange:focus,
.btn-orange.active:focus,
.btn-orange:active:focus,
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 140, 64, 0.5);
}
.btn-orange.disabled,
.btn-orange:disabled {
  background-color: #fca311;
  border-color: #fca311;
  color: #fff;
}

.btn-orange-2 {
  background-color: #fca311;
  border-color: #fca311;
  color: #fff;
}
.btn-check:focus + .btn-orange-2,
.btn-orange-2:focus,
.btn-orange-2:hover {
  background-color: #fca311;
  border-color: #fca311;
  color: #fff;
}
.btn-check:focus + .btn-orange-2,
.btn-orange-2:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 140, 64, 0.5);
}
.btn-check:active + .btn-orange-2,
.btn-check:checked + .btn-orange-2,
.btn-orange-2.active,
.btn-orange-2:active,
.show > .btn-orange-2.dropdown-toggle {
  background-color: #fca311;
  border-color: #fca311;
  color: #fff;
}
.btn-check:active + .btn-orange-2:focus,
.btn-check:checked + .btn-orange-2:focus,
.btn-orange-2.active:focus,
.btn-orange-2:active:focus,
.show > .btn-orange-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 140, 64, 0.5);
}
.btn-orange-2.disabled,
.btn-orange-2:disabled {
  background-color: #fca311;
  border-color: #fca311;
  color: #fff;
}

.btn-outline-custom {
  background-color: #fff;
  border-color: #cfcfcf;
  color: #333;
}
.btn-outline-custom:hover {
  background-color: #f2f2f2;
  border-color: #cfcfcf;
  color: #333;
}
.btn-check:focus + .btn-outline-custom,
.btn-outline-custom:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 128, 234, 0.5);
}
.btn-check:active + .btn-outline-custom,
.btn-check:checked + .btn-outline-custom,
.btn-outline-custom.active,
.btn-outline-custom.dropdown-toggle.show,
.btn-outline-custom:active {
  background-color: #fee7cf;
  border-color: #fca311;
  color: #333;
}
.btn-check:active + .btn-outline-custom:focus,
.btn-check:checked + .btn-outline-custom:focus,
.btn-outline-custom.active:focus,
.btn-outline-custom.dropdown-toggle.show:focus,
.btn-outline-custom:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 128, 234, 0.5);
}
.btn-outline-custom.disabled,
.btn-outline-custom:disabled {
  background-color: transparent;
  color: #1a84b0;
}
/* Shadows */
.shadow-lg {
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.09) !important;
}

/* Smartwizard */
.sw > .nav .nav-link > .num {
  font-weight: 500 !important;
}

/* Navbar */
.navbar {
  position: fixed; /* Membuat navbar tetap di atas ketika di-scroll */
  top: 0;
  z-index: 1000;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.875rem 1.25rem;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
@media only screen and (min-width: 768px) {
  body[data-sidebar-behavior="sticky"] .navbar {
    width: calc(100% - 260px);
  }
  body[data-sidebar-behavior="compact"] .navbar {
    width: calc(100% - 68px);
  }
  body[data-sidebar-behavior="compact"] .sidebar-content[data-simplebar] {
    height: 100vh;
    left: 0;
    position: sticky;
    top: 0;
  }
}

body[data-sidebar-behavior="compact"]
  .sidebar-nav
  > .sidebar-item
  > .sidebar-dropdown {
  background-color: #293042;
}
body[data-sidebar-behavior="compact"] .sidebar-item:hover {
  min-width: 230px;
  background-color: #fca311;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
body[data-sidebar-behavior="compact"] .sidebar-item:hover .sidebar-link svg {
  color: #293042 !important;
}
body[data-sidebar-behavior="compact"] .sidebar-item:hover .sidebar-link {
  color: #293042 !important;
  font-weight: 500;
}
body[data-sidebar-behavior="compact"] .sidebar-item.v2:hover {
  background-color: transparent !important;
  width: 100%;
}
body[data-sidebar-behavior="compact"] .sidebar-item.v2:hover .sidebar-link svg {
  color: #293042 !important;
}
body[data-sidebar-behavior="compact"] .sidebar-item.v2:hover .sidebar-link {
  color: #293042 !important;
  font-weight: 500;
}
body[data-sidebar-behavior="compact"] .sidebar-item.v2 .sidebar-item-2 {
  color: #fff !important;
  font-weight: 100;
}
body[data-sidebar-behavior="compact"]
  .sidebar-item.v2:hover
  .sidebar-item-2
  .sidebar-link {
  color: #fff !important;
  font-weight: 500;
}
body[data-sidebar-behavior="compact"]
  .sidebar-item.v2
  .sidebar-item-2.active
  .sidebar-link {
  color: #293042 !important;
  font-weight: 500;
}
body[data-sidebar-behavior="compact"]
  .sidebar-nav
  > .sidebar-item:hover
  .sidebar-link
  span {
  display: inline;
}
body[data-sidebar-behavior="compact"]
  .sidebar-nav
  > .sidebar-item.v2:hover
  .sidebar-link
  span {
  display: none;
}

@media only screen and (max-width: 680px) {
  .navbar {
    width: 100%;
  }
}

.navbar.collapsed {
  width: 100%; /* Margin untuk navbar saat sidebar collapsed */
}

/* Content */
.content {
  padding: 6rem 2.5rem 1.5rem 2.5rem;
}
@media (max-width: 767px) {
  .content {
    padding: 6rem 1rem 0 1rem;
  }
}

/* Captcha */
#canv {
  height: 35px;
}

/* Swipper */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  margin-bottom: -14px !important;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}
.swiper-pagination-bullet-active {
  background: #fca311 !important;
}

/* Separator */
.separator-or {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  border: none;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding: 24px 0 0;
}
.separator-or:after,
.separator-or:before {
  content: "";
  border-bottom: 2px solid #878a99;
  flex: 1 0 auto;
  height: 0.7em;
}
.separator-or:after {
  margin-left: 15px;
}
.separator-or:before {
  margin-right: 15px;
}
hr.separator-grey {
  background: #293042;
  height: 2px;
}

/* Profile Avatar Upload */
.profile-ava,
.profile-ava2 {
  color: transparent;
  transition: all 0.3s ease;
  position: relative;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 128px;
}

input#avatar,
input#avatar2 {
  display: none;
}

.profile-ava > img.avatar-p {
  position: absolute;
  object-fit: cover;
  height: 128px;
  width: 128px;
  border-radius: 80px;
  z-index: 0;
}

.profile-ava2 > img.avatar-p {
  position: absolute;
  object-fit: cover;
  height: 128px;
  width: 128px;
  z-index: 0;
}

.profile-ava > .-label,
.profile-ava2 > .-label {
  cursor: pointer;
  height: 128px;
  width: 128px;
  z-index: 9999;
}

.c-pointer {
  cursor: pointer;
}

#password-contain {
  display: none;
}
#password-contain p {
  padding-left: 13px;
}
#password-contain p.valid {
  color: #0ab39c;
}
#password-contain p.valid::before {
  position: relative;
  left: -8px;
  content: "✔";
}
#password-contain p.invalid {
  color: #f06548;
}
#password-contain p.invalid::before {
  position: relative;
  left: -8px;
  content: "✖";
}

.profile-ava > .-label:hover {
  color: transparent;
  transition: all 0.3s ease;
  position: relative;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-top: 0 !important;
}

.profile-ava > .-label > svg,
.profile-ava > .-label > span {
  display: none;
}
.profile-ava > .-label:hover > svg,
.profile-ava > .-label:hover > span {
  display: block;
}

.profile-ava2 > .-label:hover {
  color: transparent;
  transition: all 0.3s ease;
  position: relative;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  transition: background-color 0.2s ease-in-out;
  margin-top: 0 !important;
}

.profile-ava2 > .-label > svg,
.profile-ava2 > .-label > span {
  display: none;
}
.profile-ava2 > .-label:hover > svg,
.profile-ava2 > .-label:hover > span {
  display: block;
}

/* React Toastify */
.Toastify__toast--error {
  border: 1px solid #eb5757;
  border-radius: 50px !important;
}
.Toastify__toast--error::after {
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast--error::before {
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--success {
  border: 1px solid #3a9ea5 !important;
  border-radius: 50px !important;
}
.Toastify__toast--success::before {
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--success::after {
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast--warning {
  border: 1px solid #e78326 !important;
  border-radius: 50px !important;
}
.Toastify__toast--warning::before {
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--warning::after {
  position: absolute;
  color: #e78326;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast-body {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif;
  font-size: 13px;
  font-weight: 500 !important;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  margin-left: 10px !important;
}
.Toastify__toast > button > svg {
  display: none;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-right {
    width: 100% !important;
    transform: translateX(0);
    padding: 10px 20px !important;
  }
}

.errorMsg {
  color: #d9534f;
  font-size: 80%;
  margin-top: 0.25rem;
  width: 100%;
}

/* Tag Input Custom */
.tag-input-container {
  border-radius: 4px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: 40px;
}
.tag-input-container {
  border: 1px solid #ccc;
}
.tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
}
.tag {
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 3px 0 3px 7px;
  font-size: 12px;
  color: #fff;
  margin: 3px;
  max-width: calc(100% - 40px);
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tag2 {
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 3px 7px;
  font-size: 12px;
  color: #fff;
  margin: 3px;
  max-width: calc(100% - 40px);
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tag button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
}
.tag button:hover {
  color: #ff7e7e;
}
.tag-input {
  flex: 1;
  min-width: 150px;
}
.tag-input input {
  border: none;
  padding: 10px;
  font-size: 13px;
  background: transparent;
  outline: none;
  width: 100%;
  overflow: hidden;
  color: var(--vz-body-color);
}
.form-select:focus {
  outline: none;
  box-shadow: none;
}

/* SimpleBar */
[data-simplebar] {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}
.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}
.simplebar-offset {
  direction: inherit !important;
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}
.simplebar-content-wrapper {
  direction: inherit;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  visibility: visible;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  padding: 0 !important;
}
.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.simplebar-content:after,
.simplebar-content:before {
  content: " ";
  display: table;
}
.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}
.simplebar-height-auto-observer-wrapper {
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  -webkit-box-flex: inherit;
  -ms-flex-positive: inherit;
  flex-grow: inherit;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}
.simplebar-height-auto-observer {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}
.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}
[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}
[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}
.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 6px;
  min-height: 10px;
}
.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
  -webkit-transition: opacity 0s linear;
  transition: opacity 0s linear;
}
.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}
.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}
.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}
.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}
.custom-scroll {
  height: 100%;
}

/* RC Slider */
@media only screen and (max-width: 678px) {
  .rc-slider {
    width: 300px !important;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}
.rc-slider-disabled {
  background-color: transparent !important;
}
.rc-slider-track,
.rc-slider-tracks {
  background-color: #fb8500 !important;
}
.rc-slider-handle {
  background-color: #fff;
  border: solid 2px #fb8500 !important;
  /* border: solid 2px #96dbfa; */
}
.rc-slider-handle:active,
.rc-slider-handle:focus-visible,
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  /* border-color: #57c5f7; */
  border-color: #fb8500 !important;
  box-shadow: 0 0 5px #ffbd53 !important;
  cursor: grabbing;
}
.rc-slider-mark-text,
.rc-slider-mark-text-active {
  /* color: #666; */
  color: var(--vz-heading-color) !important;
}
.rc-slider-dot-active {
  /* border-color: #96dbfa; */
  border-color: #fb8500 !important;
}
.rc-slider-mark-text:first-child {
  transform: translateX(0) !important;
}
.rc-slider-mark-text:last-child {
  transform: translateX(-100%) !important;
}
.rc.slider-container {
  width: 100%;
  margin: 0 auto;
}
.rc.slider-label {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* User Profile */
.user-profile-img {
  position: relative;
}
.user-profile-img .profile-img {
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
  object-fit: cover;
}
@media (max-width: 991px) {
  .user-profile-img .profile-img {
    height: 160px;
  }
}
.user-profile-img .profile-foreground-img-file-input {
  display: none;
}
.user-profile-img .profile-photo-edit {
  cursor: pointer;
}
.profile-user {
  position: relative;
  display: inline-block;
}
.profile-user .profile-photo-edit {
  position: absolute;
  right: 0;
  left: auto;
  bottom: 0;
  cursor: pointer;
}
.profile-user .user-profile-image {
  -o-object-fit: cover;
  object-fit: cover;
}
.profile-user .profile-img-file-input {
  display: none;
}
.profile-user .profile-photo-edit-2 {
  bottom: 0;
  cursor: pointer;
  left: -270%;
  position: absolute;
}
.avatar-xs {
  height: 2rem;
  width: 2rem;
}
.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #212529;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

/* Loading */
.loading-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050; /* Bootstrap modal z-index is 1040 */
  overflow: hidden;
}
.loading-backdrop-2 {
  top: 5vw;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-backdrop-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050; /* Bootstrap modal z-index is 1040 */
  overflow: hidden;
}
.loading-session-list {
  margin-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  overflow: hidden;
}

/* Grid */
@media (min-width: 768px) {
  .col-25,
  .col-sm-25,
  .col-md-25,
  .col-lg-25,
  .col-xl-25,
  .col-xxl-25 {
    flex: 0 0 auto;
    width: 20.77777775%;
  }
  .col-35,
  .col-sm-35,
  .col-md-35,
  .col-lg-35,
  .col-xl-35,
  .col-xxl-35 {
    flex: 0 0 auto;
    width: 29.16666665%;
  }
}

/* List Group */
.list-group-item-action {
  color: #495057;
  text-align: inherit;
  width: 100%;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  background-color: #ededed;
  color: #495057;
  text-decoration: none;
  z-index: 1;
}
.list-group-item-action:active {
  /* background-color: #f4f7f9; */
  color: #495057;
}
.list-group-item.active {
  color: #495057;
}
.list-group-item {
  z-index: 0;
}
.list-group-item.active {
  background-color: #e7e7e7;
}
a.chats,
a.chats:hover {
  color: #333;
  text-decoration: none;
}

/*SubHeader Horizontal */
.subheader-container {
  display: flex;
  align-items: center;
  width: 100%; /* Ensures the container takes the full width */
  margin: 20px 0; /* Adds vertical spacing */
}

.subheader-title {
  margin: 0;
  padding-right: 10px; /* Adds some spacing between the title and the line */
  white-space: nowrap; /* Prevents the title from wrapping to the next line */
}

.horizontal-line {
  flex-grow: 1; /* Makes the line expand to fill the available space */
  height: 1px;
  background-color: #ccc; /* You can customize the color of the line */
}

/* Table Form */
.table.comps > :not(caption) > * > * {
  padding: 0.75rem 0.6rem;
}
.table-like {
  display: table;
  width: 100%;
}
.table-row {
  display: table-row;
}
.table-header,
.table-cell {
  display: table-cell;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: left;
}
.table-header {
  font-weight: bold;
  background-color: #f4f4f4;
}
/* Mengatur lebar spesifik untuk setiap kolom */
.table-header:nth-child(1),
.table-cell:nth-child(1) {
  width: 20%; /* Lebar untuk kolom Name */
}
.table-header:nth-child(2),
.table-cell:nth-child(2) {
  width: 15%; /* Lebar untuk kolom Type */
}
.table-header:nth-child(3),
.table-cell:nth-child(3) {
  width: 25%; /* Lebar untuk kolom Description */
}
.table-header:nth-child(4),
.table-cell:nth-child(4) {
  width: 25%; /* Lebar untuk kolom Enum */
}
.table-header:nth-child(5),
.table-cell:nth-child(5) {
  width: 5%; /* Lebar untuk kolom Required */
}
.table-header:nth-child(6),
.table-cell:nth-child(6) {
  width: 5%; /* Lebar untuk kolom Action */
}

/* Accordion */
.accordion-button {
  padding: 0.75rem 1.25rem;
}
.accordion-body > .list-group > .list-group-item:hover {
  background-color: #ededed;
}

/* Table Subscription Payment */
.table.table-payment {
  width: 100%;
  border-collapse: collapse;
}
.table.table-payment td {
  padding: 8px 12px;
}
.table.table-payment td:first-child {
  width: 30%; /* atau atur sesuai kebutuhan */
}

/* Navlink */
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #f7c594;
  border-color: #dee6ed #dee6ed #f7f9fc;
  color: #000;
}
.nav-tabs .nav-link,
.nav-tabs .nav-link:hover {
  border: 0;
  color: #000;
}

/* Markdown Chat */
.markdown p {
  margin-bottom: 0.5rem;
}
.markdown a {
  color: #fca311;
}
/* Reset default list styles */
.markdown ol {
  counter-reset: item !important;
  list-style: none;
  list-style-position: inside;
  padding-left: 0;
  margin-bottom: 0.3rem;
}
.markdown ol > li {
  counter-increment: item !important;
  margin-left: 2rem !important;
  /* text-indent: -2.3em;
  padding-left: 2.3em; */
}
.markdown ol > li::before {
  position: absolute !important;
  content: counter(item) "." !important;
  display: inline-block !important;
  width: 1.3em !important;
  text-align: right !important;
  margin-left: -2rem !important;
}

.markdown ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 0;
  margin-bottom: 0.3rem;
}
.markdown ul > li {
  position: relative;
  margin-left: 1.5rem !important;
}
.markdown ul > li::before {
  content: "•" !important;
  font-size: 18px !important;
  position: absolute !important;
  left: 0 !important;
  margin-top: -0.25rem !important;
  margin-left: -1.3rem !important;
}
/* Subitem */
.markdown ol > li > ol,
.markdown ul > li > ol {
  counter-reset: subitem !important;
}
.markdown ol > li > ul,
.markdown ul > li > ul {
  counter-reset: subitem !important;
}
.markdown ol > li > ol > li,
.markdown ul > li > ol > li {
  counter-increment: subitem !important;
}
.markdown ol > li > ol > li::before,
.markdown ul > li > ol > li::before {
  font-size: 12px !important;
  content: counter(subitem) "." !important;
}
.markdown table {
  width: 100%;
  margin-bottom: 0.5rem;
  vertical-align: top;
  border-color: #e9ebec;
}
.markdown table > :not(caption) > * > * {
  padding: 0.4rem 0.45rem;
}
.markdown th {
  text-transform: uppercase !important;
}
.markdown th,
.markdown td {
  border: 1px solid #212529;
  padding: 8px;
  text-align: left;
}
.markdown h1 {
  margin-top: 0.5rem;
  font-size: 22px;
}
.markdown h2 {
  margin-top: 0.5rem;
  font-size: 20px;
}
.markdown h3 {
  margin-top: 0.5rem;
  font-size: 18px;
}

/* Emoji Picker Chat */
.emoji-picker-container {
  position: absolute;
  bottom: 70px;
  z-index: 1;
}
.epr-main.EmojiPickerReact {
  height: 400px !important;
  width: 320px !important;
}
.epr-category-nav {
  padding: 0 10px 10px 10px !important;
}
.emoji-picker-container input {
  height: 35px !important;
}
.emoji-picker-container > aside > div:nth-child(3) {
  height: 40px !important;
}
.epr-emoji-img {
  padding: 8px !important;
}
/* Atur tampilan tombol kategori emoji */
.epr-emoji-category-label {
  font-size: 14px !important;
}
